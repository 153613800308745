import Vue from 'vue'
import PeruData from '@/Dictionaries/Peru.js'
import ChileData from '@/Dictionaries/Chile.js'

const defaultTemplate = () => import('@/assets/sass/default.scss')
const cuadrado = () => import('@/assets/sass/cuadrado.scss')

function getFormattedDate(date){
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day  = ("0" + (date.getDate())).slice(-2);
    var year = date.getFullYear();
    return year + "-" + month + "-" + day;
}

const state = {
    customer: null,
    version: 1,
    projectId: null,
    defaultProjectId: null,
    fetchedProjects: null,
    useProjectImage: false,
    atLeastOneVitalResponseFailed: false,
    errorMessageTitle: "¡Algo salió mal!",
    errorMessage: "Por favor inténtalo de nuevo más tarde",
    assetMaps: null,
    propertyNames: {
    id: 'id',
        projectImage: 'urlImagenUnoProyectoCompleta'
    },
    template: 'default-template',
    validCountryCodes: { chile: 'CL', peru: 'PE' },
    countryCode: null,
    utmData: {
    utmCampaign: null,
    utmContent: null,
    utmMedium: null,
    utmSource: null,
    utmTerm: null,
    },
    ufValue: null,
    themeColors: {
        primaryColor: null,
        secondaryColor: null,
    },
    vitalDataLoaded: { opportunities: false, projectData: false },
    warehousesMaxNumber: null,
    warehousesMinNumber: null,
    parkingLotsMaxNumber: null,
    parkingLotsMinNumber: null,
    isMinParkingLotsNumberFulfilled: false,
    isMinWarehouseNumberFulfilled: false,
    useQuoteService: null,
    useOpportunityDiscount: null,
    paymentData: {
      status: null,
      reservation: {
        localCurrencyValue: 0.00,
        percentage: 0,
        adjustmentValue: 0.00,
      },
      funding: {
        localCurrencyValue: 0.00,
        percentage: 0,
        adjustmentValue: 0.00,
      },
      mortgageCredit: {
        localCurrencyValue: 0.00,
        percentage: 0,
        adjustmentValue: 0.00,
      }
    },
    loadingPaymentPlan: false,
    noTitle: false,
    title: null,
    subtitle: null,
}
const mutations = {
    setProjectId: (state, data) => (state.projectId = data),
    setUseProjectImage: (state, data) => (state.useProjectImage = data),
    setNoTitle: (state, data) => (state.noTitle = data),
    setTitle: (state, data) => (state.title = data),
    setSubtitle: (state, data) => (state.subtitle = data),
    setTemplate: (state, data) => {
      state.template = data
      switch (data) {
        case 'cuadrado':
          cuadrado()
          break
        default:
          defaultTemplate()
          break
      }
    },
    setVersion: (state, data) => (state.version = data),
    setDefaultProjectId: (state, data) => (state.defaultProjectId = data),
    setProjects: (state, data) => (state.fetchedProjects = data),
    setAssetMaps: (state, data) => (state.assetMaps = data),
    setCustomer: (state, data) => (state.customer = data),
    setAtLeastOneVitalResponseFailed: state => (state.atLeastOneVitalResponseFailed = true),
    setErrorMessageTitle: (state, data) => (state.errorMessageTitle = data),
    setErrorMessage: (state, data) => (state.errorMessage = data),
    setCountry: (state, countryCode) => {
    // Country code must be alpha-2 code (ISO 3166-1)

    if (!Object.values(state.validCountryCodes).includes(countryCode)) {
      // TODO: Show error if not valild country code.
      console.log('Not valid country code')
      return
    }

    state.countryCode = countryCode
  },
    setUtmData: (state, payload) => (state.utmData = payload),
    setUfValue: (state, data) => (state.ufValue = data),
    setThemeColors: (state, payload) => state.themeColors = payload,
    setVitalDataLoaded: (state, property) =>
		(state.vitalDataLoaded[property] = true),
    setMaxAndMins: (state) => {
        const project = state.fetchedProjects.find(project => project.id == state.projectId)
        if (project.maximosMinimosBienes.length > 0) {
            const wareHousesData = project['maximosMinimosBienes'].find(
                property => property['tipoBien'] == 'BODEGA'
            )
            const parkingLotsData = project['maximosMinimosBienes'].find(
                property => property['tipoBien'] == 'ESTACIONAMIENTO'
            )
            if (wareHousesData) {
                if (wareHousesData.cantidadMaxima)
                    state.warehousesMaxNumber = wareHousesData.cantidadMaxima
                else
                    state.warehousesMaxNumber = null
                if (wareHousesData.cantidadMinima)
                    state.warehousesMinNumber = wareHousesData.cantidadMinima
                else
                    state.warehousesMinNumber = null
            }
            if (parkingLotsData) {
                if (parkingLotsData.cantidadMaxima)
                    state.parkingLotsMaxNumber = parkingLotsData.cantidadMaxima
                else
                    state.parkingLotsMaxNumber = null
                if (parkingLotsData.cantidadMinima)
                    state.parkingLotsMinNumber = parkingLotsData.cantidadMinima
                else
                    state.parkingLotsMinNumber = null
            }
        }
        else {
            state.warehousesMaxNumber = null
            state.warehousesMinNumber = null
            state.parkingLotsMaxNumber = null
            state.parkingLotsMinNumber = null
        }
    },
    setIsMinParkingLotsNumberFulfilled: (state, payload) =>
        state.isMinParkingLotsNumberFulfilled = payload,
    setIsMinWarehouseNumberFulfilled: (state, payload) =>
        state.isMinWarehouseNumberFulfilled = payload,
    setQuoteService: (state, payload) => state.useQuoteService = payload,
    setQuoteDiscountLogic: (state, payload) => state.useOpportunityDiscount = payload,
    setPaymentData: (state, payload) => state.paymentData = payload,
    setloadingPaymentPlan: (state, data) => state.loadingPaymentPlan = data,
}
const actions = {
    async fetchProjectData({ state, commit }) {
      const version = state.version == 2 ? 'v3' : 'v2'
        await Vue.axios
            .get(`${version}/quotes/list-projects`, {
                params: {
                    real_estate: state.customer,
                },
            })
            .then(response => {
              if (!response.data.length) {
                console.log('No projects found - assets')
                commit('setAtLeastOneVitalResponseFailed')
                commit('setErrorMessageTitle', "¡Lo sentimos!")
                commit('setErrorMessage', "No se encontraron proyectos por el momento. Por favor, inténtelo más tarde.")
              }
              else {
                commit('setProjects', response.data)
              }
              commit('setVitalDataLoaded', 'projectData')
            })
            .catch(error => {
                commit('setVitalDataLoaded', 'projectData')
                commit('setAtLeastOneVitalResponseFailed')
                console.log(error)
            })
    },
    async fetchAssetMaps({ state, commit }) {
      const version = state.version == 2 ? 'v3' : 'v2'
        await Vue.axios
            .get(`${version}/quotes/get-asset-map-images`, {
                params: {
                    real_estate: state.customer,
                    project_id: state.projectId
                },
            })
            .then(response => {
        if (!response.data.length) {
          console.log('Map images not found')
        }
                else {
                    commit('setAssetMaps', response.data)
                }
            })
            .catch(error => {
                console.log(error)
            })
    },
    async fetchUfData({ state, commit }) {
      const version = state.version == 2 ? 'v3' : 'v2'
        await Vue.axios
            .get(`${version}/opportunities/get_uf`, {
                params: {
                    customer: state.customer,
                    date: getFormattedDate(new Date())
                },
            })
            .then(response => {
                commit('setUfValue', response.data)
            })
            .catch(error => {
                console.log(error)
            })
    },
    async fetchPaymentPlan({ state, rootGetters, commit }) {
      const version = state.version == 2 ? 'v3' : 'v2'
      await Vue.axios
          .post(`${version}/opportunities/calculate-payment`, {
            customer: state.customer,
            discountId: rootGetters['opportunities/getOpportunityDiscount']['id'],
            assets: rootGetters['assets/getSelectedAssetsId'],
          })
          .then(response => {
            const reservationData = response.data.find(paymentMethod => paymentMethod['formaPago']['descripcionFormaPago'] == 'Reserva' )
            let fundingData = response.data.find(paymentMethod => paymentMethod['formaPago']['descripcionFormaPago'] == 'Financiamiento Propio' )
            const mortgageCreditData = response.data.find(paymentMethod => paymentMethod['formaPago']['descripcionFormaPago'] == 'Crédito Hipotecario' )
            if (!fundingData)
              fundingData = response.data.find(paymentMethod => ['Cuotas durante la construccion', 'Cuotas durante la construcción'].includes(paymentMethod['formaPago']['descripcionFormaPago']))
            const payload = {
              status: 'success',
              reservation: {
                localCurrencyValue: reservationData ? reservationData['pagoEnMonedaLocal'] : 0.00,
                percentage: reservationData ? reservationData['pagoEnPorcentaje'] : 0.00,
                adjustmentValue: reservationData ? reservationData['pagoEnValorDeAjuste'] : 0.00,
              },
              funding: {
                localCurrencyValue: fundingData ? fundingData['pagoEnMonedaLocal'] : 0.00,
                percentage: fundingData ? fundingData['pagoEnPorcentaje'] : 0.00,
                adjustmentValue: fundingData ? fundingData['pagoEnValorDeAjuste'] : 0.00,
              },
              mortgageCredit: {
                localCurrencyValue: mortgageCreditData ? mortgageCreditData['pagoEnMonedaLocal'] : 0.00,
                percentage: mortgageCreditData ? mortgageCreditData['pagoEnPorcentaje'] : 0.00,
                adjustmentValue: mortgageCreditData ? mortgageCreditData['pagoEnValorDeAjuste'] : 0.00,
              }
            }
            commit('setPaymentData', payload)
            commit('setloadingPaymentPlan', false)
          })
          .catch(error => {
              console.log(error)
              const payload = {
                status: 'error',
                reservation: {
                  localCurrencyValue: 0.00,
                  percentage: 0,
                  adjustmentValue: 0.00,
                },
                funding: {
                  localCurrencyValue: 0.00,
                  percentage: 0,
                  adjustmentValue: 0.00,
                },
                mortgageCredit: {
                  localCurrencyValue: 0.00,
                  percentage: 0,
                  adjustmentValue: 0.00,
                }
              }
              commit('setPaymentData', payload)
              commit('setloadingPaymentPlan', false)
          })
  },
}
const getters = {
  getProjectData: (state, getters, rootState) => {
        const project = state.fetchedProjects.find(project => project[state.propertyNames.id] == rootState.opportunities.selectedOpportunity.proyecto)
        const projectImage = project[state.propertyNames.projectImage]
        ? project[state.propertyNames.projectImage]
        : null
        return {...project, ...{image: projectImage}}
    },
    getProjects: state => state.fetchedProjects,
    getAssetMaps: state => state.assetMaps,
    getIsChilean: state => state.validCountryCodes.chile === state.countryCode,
    getSlang: (state, getters) => (getters.getIsChilean ? ChileData : PeruData),
    getUfValue: state => state.ufValue,
    getErrorMessageTitle: state => state.errorMessageTitle,
    getErrorMessage: state => state.errorMessage,
    getWarehousesMaxNumber: state => state.warehousesMaxNumber,
    getWarehousesMinNumber: state => state.warehousesMinNumber,
    getParkingLotsMaxNumber: state => state.parkingLotsMaxNumber,
    getParkingLotsMinNumber: state => state.parkingLotsMinNumber,
    getIsMinParkingLotsNumberFulfilled: state => state.isMinParkingLotsNumberFulfilled,
    getIsMinWarehouseNumberFulfilled: state => state.isMinWarehouseNumberFulfilled,
    getTemplate: state => {
      switch (state.template) {
        case 'cuadrado':
          return 'cuadrado'
        default:
          return 'default'
      }
    },
    getUseProjectImage: state => state.useProjectImage,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
